<template>
    <v-card height="100%" class="add-location-card">
        <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">
                <span class="headline">{{ paymentData.pendingPayment ? 'Update' : 'Add' }} Pending Payment</span>
            </div>
            <v-btn icon @click="$emit('close-dialog')">
                <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="valid" @submit.prevent>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="12">

                        <v-text-field v-model="paymentData.bookingId" label="Booking ID *"
                        outlined dense
                            placeholder="Booking ID *" hide-details="auto" class="mb-6" readonly></v-text-field>

                        <v-text-field v-model="paymentData.pendingAmount" :rules="[(value) => !!value || 'This field is required']"
                        
                            validate-on-blur outlined dense type="number" label="Payment Amount *" placeholder="Payment Amount *"
                            hide-details="auto" class="mb-6"></v-text-field>

                        <v-select v-model="paymentData.paymentStatus" :rules="[validators.required]" validate-on-blur
                            outlined label="Payment Status *" placeholder="Status *" :items="typeOptions"
                            item-text="title" item-value="value" dense hide-details="auto" class="mb-12"></v-select>

                        <v-textarea v-model="paymentData.note" outlined dense label="Note" placeholder="Note"
                            hide-details="auto" class="mb-6"></v-textarea>

                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-alert dense text color="error" v-if="error">
                    {{ error }}
                </v-alert>
                <v-spacer> </v-spacer>

                <v-btn color="primary" class="me-3" @click.prevent="onSubmit" :loading="loading">
                    {{ paymentData.pendingPayment ? 'Update' : 'Add' }}
                </v-btn>
                <v-btn color="error" outlined @click="$emit('close-dialog', false)"> Cancel </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>
  
<script>
import { mdiClose, mdiCircle, mdiCalendar } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { Timestamp } from 'firebase/firestore'

export default {
    props: {
        error: {
            type: String | null,
            default: null,
        },
        addPendingPayment: {
            type: Function,
        },
        selectedBooking: {
            type: Object | null,
            default: null,
        }
    },
    setup(props, { emit }) {
        const blankpaymentData = {
            bookingId: '',
            pendingAmount: '',
            paymentStatus: ''
        }
        const typeOptions = [
            { title: 'Paid', value: 'Paid' },
            { title: 'UnPaid', value: 'UnPaid' },
        ]
    
        const valid = ref(false)
        const loading = ref(false)
        const form = ref(null)
        const locationRef = ref(null)
        const paymentData = ref(JSON.parse(JSON.stringify(blankpaymentData)))

        watch(
            () => props.selectedBooking,
            () => {
                if (props.selectedBooking) {
                    paymentData.value.bookingId = props.selectedBooking.booking_id
                    paymentData.value.pendingAmount = props.selectedBooking.pending_amount
                    paymentData.value.paymentStatus = props.selectedBooking.pending_payment_status
                    paymentData.value.pendingPayment = props.selectedBooking.pending_payment ? props.selectedBooking.pending_payment : false
                    paymentData.value.note = props.selectedBooking.pending_payment_note
                } else {
                    paymentData.value = JSON.parse(JSON.stringify(blankpaymentData))
                }
            },
            { immediate: true },
        )
        const validate = () => {
            form.value.validate()
        }

        const onSubmit = async () => {
            if (valid.value) {
                loading.value = true
                let final = {
                    booking_id: paymentData.value.bookingId,
                    pending_payment_status: paymentData.value.paymentStatus,
                    pending_amount: Number(paymentData.value.pendingAmount),
                    pending_payment: true,
                    pending_payment_note: paymentData.value.note ? paymentData.value.note : ''
                }
                await props.addPendingPayment(props.selectedBooking.id, final);
                emit('close-dialog', false)

                let pendingPaymentData = [{
                    booking_id: paymentData.value.bookingId,
                    pending_payment_amount: paymentData.value.pendingAmount,
                    payment_status: paymentData.value.paymentStatus,
                    pending_payment_note: paymentData.value.note
                }]
                emit('payment-data', pendingPaymentData)
                loading.value = false
            } else {
                validate()
            }
        }
        return {
            typeOptions,
            form,
            onSubmit,
            paymentData,
            // locationData,
            valid,
            loading,
            validate,
            locationRef,
            // validation
            validators: { required },
            icons: {
                mdiClose,
                mdiCircle,
                mdiCalendar,
            },
        }
    },
}
</script>
<style lang="scss" scoped>
.add-location-card {
    .location-input {
        border-style: solid;
        border-color: grey;
        border-width: 1px;
        border-radius: 5px;
        font-weight: 400;

        input {
            color: white;
            width: 100%;
            border-radius: 5px;
            line-height: 2.5;
            padding-left: 10px;
            font-size: 16px;
        }
    }
}
</style>
  