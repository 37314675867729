import useCommon from '@/composables/useCommon'
import { computed, ref, watch } from '@vue/composition-api'
import {
    addDoc,
    collection,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    updateDoc,
    where,
} from 'firebase/firestore'
import { filter, isNil } from 'lodash'
import { db } from '/src/firebase/config'

export default function useUsersList() {
    const { getOptions, stringContainsAny } = useCommon()
    const couponListTable = ref([])
    const filteredCouponListTable = ref([])
    const error = ref(null)

    const tableColumns = [
        { text: '#', value: 'srno', sortable: false },
        { text: 'Code', value: 'code' },
        { text: 'Description', value: 'description' },
        { text: 'Type', value: 'ltype' },
        { text: 'Discount', value: 'discount', align: 'center' },
        { text: 'Start Date', value: 'startdate' },
        { text: 'End Date', value: 'enddate' },
        { text: 'Updated on', value: 'updatedon' },
        { text: 'Deduct Deposit', value: 'removedeposit', align: 'center' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    ]

    const searchQuery = ref('')
    const statusFilter = ref(true)
    const typeFilter = ref(null)
    const statusOptions = [
        {
            title: 'Active',
            value: true,
        },
        {
            title: 'Inactive',
            value: false,
        },
    ]
    const typeOptions = computed(() => {
        return getOptions(couponListTable.value, 'ltype')
    })
    const loading = ref(false)
    let unsubscribe = null
    const options = ref({
        sortBy: ['updatedon'],
        sortDesc: [true],
    })
    const dateRange = ref([])

    const queryOptions = () => {
        const qOptions = []

        const orderByKey = options.value.sortBy[0] ? options.value.sortBy[0] : 'updatedon'
        const order = options.value.sortDesc[0] ? 'desc' : 'asc'
        qOptions.push(orderBy(orderByKey, order))

        return qOptions
    }

    // fetch data
    const fetchCoupons = async () => {
        try {
            if (unsubscribe) {
                unsubscribe()
            }
            loading.value = true
            couponListTable.value = []
            filteredCouponListTable.value = []

            const q = query(collection(db, 'coupon'), ...queryOptions())
            unsubscribe = onSnapshot(q, querySnapshot => {
                couponListTable.value = []
                querySnapshot.forEach(doc => {
                    couponListTable.value.push({
                        id: doc.id,
                        ...doc.data(),
                    })
                })
                filteredCouponListTable.value = couponListTable.value
                filterCoupons()
            })
        } catch (e) {
            console.log(e)
        } finally {
            loading.value = false
        }
    }

    const checkIfCouponExist = async code => {
        const q = query(collection(db, 'coupon'), where('code', '==', code))
        const querySnapshot = await getDocs(q)
        return !!querySnapshot.size
    }

    const addPendingPayment = async (id, data) => {
        try {
            error.value = null
            const q = query(collection(db, "cdrive_booking"), where("booking_id", "==", data.booking_id));
            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs.length !== 0){
                const docReference = doc(db, 'cdrive_booking', id)
                await updateDoc(docReference, data)
            }else{
                const docReference = doc(db, 'sdrive_booking', id)
                await updateDoc(docReference, data)
            }
        } catch (e) {
            alert(e)
            error.value = e.message
            console.log(e)
        }
    }

    const editCoupon = async (id, coupon) => {
        try {
            error.value = null
            const prevCoupon = filter(couponListTable.value, coupon => coupon.id == id)
            const couponData = {
                ...coupon,
                updatedon: serverTimestamp(),
            }
            if (prevCoupon.length && prevCoupon[0].code != couponData.code) {
                if (await checkIfCouponExist(coupon.code)) {
                    throw new Error('Coupon already exist!')
                }
            }
            const docReference = doc(db, 'coupon', id)
            await updateDoc(docReference, couponData)
        } catch (e) {
            error.value = e.message
            console.log(e)
        }
    }

    const filterCoupons = () => {
        let filteredEnquiries = couponListTable.value
        if (searchQuery.value && searchQuery.value.length) {
            filteredEnquiries = filter(filteredEnquiries, ({ code, description, discount, discountAmt, ltype }) => {
                return stringContainsAny(code + description + discount + discountAmt + ltype, searchQuery.value)
            })
        }

        if (!isNil(statusFilter.value)) {
            filteredEnquiries = filter(filteredEnquiries, ({ active }) => {
                return statusFilter.value == active
            })
        }

        if (typeFilter.value && typeFilter.value.length) {
            filteredEnquiries = filter(filteredEnquiries, ({ ltype }) => {
                return typeFilter.value == ltype
            })
        }

        filteredCouponListTable.value = filteredEnquiries
    }

    watch([searchQuery, statusFilter, typeFilter], () => {
        filterCoupons()
    })

    const unsubscribeCoupons = () => {
        unsubscribe()
    }

    fetchCoupons()

    return {
        couponListTable,
        filteredCouponListTable,
        tableColumns,
        searchQuery,
        loading,
        options,
        dateRange,
        statusOptions,
        typeOptions,
        statusFilter,
        typeFilter,
        error,

        unsubscribeCoupons,
        addPendingPayment,
        editCoupon,
    }
}
