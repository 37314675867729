<template>
  <div id="user-list" class="car-brand">
    <!-- list filters -->
    <v-card>
      <v-card-title class="d-flex">
        <div class="mr-auto">Car Bookings</div>
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            clearable
            class="text-search me-3 mb-4"
          >
          </v-text-field>
          <v-btn icon class="mb-4" @click="showFilters = !showFilters">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiFilter }}
                </v-icon>
              </template>
              <span>Filters</span>
            </v-tooltip>
          </v-btn>
          <v-btn v-if="resportDownload" icon class="mb-4" @click="exportToExcel()">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="24" class="me-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiDownload }}
                </v-icon>
              </template>
              <span>Download</span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="d-flex flex-wrap pb-0" v-if="showFilters">
        <!-- search -->
        <v-select
          v-model="statusFilter"
          placeholder="Select Status"
          :items="statusOptions"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-select
          v-model="typeFilter"
          placeholder="Select Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
          v-if="country == 'India'"
        ></v-select>

        <v-select
          v-model="locationFilter"
          placeholder="Select Location"
          :items="locationOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
          @change="vendorFilter = null"
        ></v-select>

        <v-select
          v-model="vendorFilter"
          placeholder="Select Vendor"
          :items="vendorOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
          v-if="country == 'India'"
        ></v-select>

        <v-select
          v-model="packageFilter"
          placeholder="Select Package"
          :items="packageOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
          v-if="country == 'India'"
        ></v-select>

        <v-select
          v-model="platformFilter"
          placeholder="Select Platform"
          :items="platformOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <!-- <v-dialog ref="dialog" v-model="dateModal" :return-value.sync="dateRange" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRange"
              placeholder="Select Date Range"
              :prepend-inner-icon="icons.mdiCalendar"
              readonly
              outlined
              hide-details
              dense
              clearable
              v-bind="attrs"
              v-on="on"
              class="user-search me-3 mb-4"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(dateRange)"> OK </v-btn>
          </v-date-picker>
        </v-dialog> -->

        <v-select
          v-model="travelDateFilter"
          placeholder="Select Travel Date"
          :items="travelDateOptions"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
        ></v-select>

        <v-dialog
          v-if="travelDateFilter == 'Custom'"
          ref="dialog1"
          v-model="dateModal1"
          :return-value.sync="dateRange1"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRange1"
              placeholder="Select Date"
              :prepend-inner-icon="icons.mdiCalendar"
              readonly
              outlined
              hide-details
              dense
              clearable
              v-bind="attrs"
              v-on="on"
              class="user-search me-3 mb-4"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="dateRange1" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateModal1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.dialog1.save(dateRange1)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="filteredCarBookingsList"
        :items-per-page="10"
        :item-class="itemRowBackground"
        :options.sync="options"
        :expanded="filteredCarBookingsList"
        :loading="carBookingList.length == 0"
        :footer-props="{ itemsPerPageOptions: [10, 50, 100, -1] }"
      >
        <template v-slot:header.amount="{ header }">
          {{ header.text }}<br />
          <small class="error--text">( pending )</small>
        </template>
        <template v-slot:item.srno="{ index, item }">
          <span>{{ index + 1 }}</span
          ><br />
          <v-icon size="14">{{ icons[getPlatformIconName(item.mplatform)] }}</v-icon>
        </template>
        <template v-slot:item.booking_id="{ item }">
          <v-list-item class="px-0">
            <v-list-item-content>
              <span class="white--text">{{ item.booking_id || 'Pending' }}</span
              ><br />
              <small>{{ item.type == 'Self Drive' ? item.type : item.package }}</small>
              <template v-if="item.status != 'Done' || country != 'India'">
                <br />
                <small>
                  {{ formatDate(item.createdon.toDate(), 'DD-MMM-YYYY hh:mm:ss A') }}
                </small>
              </template>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item.customer_name="{ item }">
          <span>{{ item.customer_name }}</span
          ><br />
          <small>{{ item.dialcode }} {{ item.phone }}</small>
        </template>
        <template v-slot:item.location="{ item }">
          <span v-if="item.type == 'Self Drive'">{{ item.carbrand }} {{ item.carclass }}</span>
          <span v-else>{{ item.brand }} {{ item.class }}</span
          ><br />
          <small>{{ item.location }}</small>
        </template>
        <template v-slot:item.start_date="{ item }">
          <span>{{ item.start_date }} {{ item.start_time }}</span>
          <br />
          <span>{{ item.end_date }} {{ item.end_time }}</span>
          <br />
          <small class="warning--text">{{ item.isAgentEdited ? '(Edited)' : '' }}</small>
        </template>
        <template v-slot:item.amount="{ item }">
          <span>{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(item.amount) }}</span>
          <br />
          <span class="error--text" v-if="item.pending_payment && item.pending_payment_status == 'UnPaid'"
            >{{ $store.getters['appConfig/currencySymbol'] }} {{ formatNumber(item.pending_amount) }}</span
          >
          <span class="success--text" v-if="item.pending_payment && item.pending_payment_status == 'Paid'"
            >- PAID -</span
          >
        </template>
        <template v-slot:item.type="{ item }">
          <span v-if="item.isamexused">PQ</span>
          <span v-else-if="item.pendingtype == 'Admin'">Admin</span>
          <template v-else-if="item.pendingtype == 'Partner'">
             <span >{{item.partner_uuid}}</span>
             <br/>
            <small class="primary--text">{{ item.rp_pay_link ? '(Link)' : '' }}</small>
          </template>
          <span v-else>User</span>
          <br />
          <v-chip v-if="item.repeated_user" class="v-chip-light-bg white--text" x-small disabled>Repeated</v-chip>
        </template>
        <template v-slot:item.createdon="{ item }">
          <span>{{ formatDate(item.createdon.toDate(), 'DD-MMM-YYYY') }}</span
          ><br />
          <span>{{ formatDate(item.createdon.toDate(), 'hh:mm:ss A') }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip small class="v-chip-light-bg" :class="item.status == 'Done' ? 'success--text' : 'error--text'">{{
            item.status
          }}</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu bottom offset-y>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                <v-icon color="primary">{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="action-item">
                <v-list-item-title @click="viewDetails(item)">View Details</v-list-item-title>
              </v-list-item>
              <template v-if="country == 'India'">
                <template v-if="item.status == 'Done'">
                  <v-list-item class="action-item" v-if="item.agentassigned && !item.iscancelled">
                    <v-list-item-title @click="sendDriveDetails(item)">Send Driver Details</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="action-item" v-if="!item.agentassigned && !item.iscancelled">
                    <v-list-item-title @click="assignAgent(item)">Assign Vendor</v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item class="action-item" v-else>
                  <v-list-item-title @click="showConfirmBookingDialog(item)"> Confirm Booking</v-list-item-title>
                </v-list-item>
              </template>
              <v-list-item class="action-item" v-if="!item.iscancelled">
                <v-list-item-title @click="cancelBooking(item)">Cancel Booking</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:expanded-item="{ headers, item }" v-if="country == 'India'">
          <td :colspan="headers.length" v-if="item.status == 'Done'">
            <div class="timeline">
              <div class="d-flex">
                <div class="text-center">
                  <v-chip
                    class="v-chip-light-bg action-chip"
                    :class="bookingCreatedClass(item)"
                    small
                    :disabled="bookingCreatedClass(item) == 'success--text'"
                    @click="showConfirmBookingDialog(item)"
                  >
                    Booking Created </v-chip
                  ><br />
                  <small class="action-chip-desc white--text">
                    {{ formatDate(item.createdon.toDate(), 'DD-MMM-YYYY hh:mm:ss A') }}
                  </small>
                </div>
                <v-spacer>
                  <div class="line"></div>
                </v-spacer>
                <div class="text-center">
                  <v-chip
                    class="v-chip-light-bg action-chip"
                    :class="assignAgentClass(item)"
                    small
                    @click="assignAgent(item)"
                    :disabled="item.iscancelled"
                  >
                    {{ assignAgentClass(item) == 'success--text' ? 'Vendor Assigned' : 'Assign Vendor' }}
                  </v-chip>
                  <br />
                  <small class="action-chip-desc white--text">
                    {{ item.agentname }}
                  </small>
                </div>
                <v-spacer>
                  <div class="line"></div>
                </v-spacer>
                <div class="text-center">
                  <v-chip
                    class="v-chip-light-bg action-chip"
                    :class="sendDriverDetailsClass(item)"
                    small
                    :disabled="sendDriverDetailsClass(item) != 'warning--text' || item.iscancelled"
                    @click="sendDriveDetails(item)"
                  >
                    {{
                      sendDriverDetailsClass(item) == 'success--text' ? 'Driver Details Sent' : 'Send Driver Details'
                    }}
                  </v-chip>
                </div>
                <v-spacer>
                  <div class="line"></div>
                </v-spacer>
                <v-chip class="v-chip-light-bg action-chip" :class="otpVerifiedClass(item)" small disabled>
                  {{ otpVerifiedClass(item) == 'success--text' ? 'OTP verified' : 'Verify OTP' }}
                </v-chip>
                <template v-if="item.iscancelled">
                  <v-spacer>
                    <div class="line"></div>
                  </v-spacer>
                  <div class="text-center">
                    <v-chip class="v-chip-light-bg action-chip error--text" small disabled> Booking Cancelled </v-chip>
                    <br />
                    <small class="action-chip-desc white--text">
                      {{ formatDate(item.cancelledon.toDate(), 'DD-MMM-YYYY hh:mm:ss A') }}
                    </small>
                  </div>
                </template>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>

      <v-dialog v-model="confirmBookingDialog" max-width="600">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">Confirm Booking?</div>
            <v-btn icon @click="confirmBookingDialog = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <p>Are you sure you want to confirm this booking?</p>
                <p>Ensure that you have collected the payment.</p>
                <p>This will trigger emails and SMS to all the concerned parties including the customer.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" class="me-3" :loading="loading" @click="confirmBooking()"> Confirm </v-btn>
                <v-btn color="error" @click="confirmBookingDialog = false"> Cancel </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="detailDialog" :max-width="country == 'India' ? 1000 : 600">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">Booking Details</div>
            <v-btn icon @click="detailDialog = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-tabs v-model="detailTab" background-color="transparent" color="basil" grow>
              <v-tab> Summary </v-tab>
              <v-tab v-if="country == 'India'"> Other Details </v-tab>
              <v-tab> Pending Payment </v-tab>
            </v-tabs>

            <v-tabs-items v-model="detailTab">
              <v-tab-item>
                <v-card flat class="dialog-card">
                  <v-card-text v-if="selectedBooking">
                    <v-row>
                      <v-col v-if="country == 'India'">
                        <v-card class="mb-7">
                          <v-card-title> Booking Activity Timeline </v-card-title>

                          <v-card-text>
                            <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
                              <v-timeline-item :color="bookingCreatedClass(selectedBooking).slice(0, -6)" small>
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">Booking Created</h4>
                                  <!-- <small class="text-no-wrap">
                                    {{ formatDate(selectedBooking.createdon.toDate(), 'DD-MMM hh:mm:ss A') }}
                                  </small> -->
                                </div>

                                <div class="d-flex align-center mt-3">
                                  <v-avatar size="40" color="primary" class="me-2 v-avatar-light-bg primary--text">
                                    <span class="font-weight-semibold">{{
                                      avatarText(selectedBooking.customer_name)
                                    }}</span>
                                  </v-avatar>
                                  <div>
                                    <p class="text--primary font-weight-medium mb-0">
                                      {{ selectedBooking.customer_name }}
                                    </p>
                                    <span>Customer</span><br />
                                    <small>{{ selectedBooking.phone }}</small>
                                  </div>
                                </div>
                              </v-timeline-item>

                              <v-timeline-item :color="assignAgentClass(selectedBooking).slice(0, -6)" small>
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">Vendor Assigned</h4>
                                  <!-- <small class="text-no-wrap"> Timestamp here </small> -->
                                </div>

                                <div v-if="selectedBooking.oldAgentName" class="d-flex align-center mt-3">
                                  <v-avatar size="40" color="primary" class="me-2 v-avatar-light-bg primary--text">
                                    <span class="font-weight-semibold">{{
                                      avatarText(selectedBooking.oldAgentName)
                                    }}</span>
                                  </v-avatar>
                                  <div>
                                    <p class="text--primary font-weight-medium mb-0">{{ selectedBooking.oldAgentName }}</p>
                                    <span>Pre Vendor</span>
                                  </div>
                                </div>

                                <div class="d-flex align-center mt-3" v-if="selectedBooking.agentname">
                                  <v-avatar size="40" color="primary" class="me-2 v-avatar-light-bg primary--text">
                                    <span class="font-weight-semibold">{{
                                      avatarText(selectedBooking.agentname)
                                    }}</span>
                                  </v-avatar>
                                  <div>
                                    <p class="text--primary font-weight-medium mb-0">{{ selectedBooking.agentname }}</p>
                                    <span>Vendor</span>
                                  </div>
                                </div>
                              </v-timeline-item>

                              <v-timeline-item :color="sendDriverDetailsClass(selectedBooking).slice(0, -6)" small>
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">Driver details sent to customer</h4>
                                  <!-- <small class="text-no-wrap" v-if="driverTransactions.length">
                                    {{ formatDate(driverTransactions[0].createdon.toDate(), 'DD-MMM hh:mm:ss A') }}
                                  </small> -->
                                </div>
                                <div class="d-flex align-center mt-3" v-if="driverTransactions.length">
                                  <v-avatar size="40" color="primary" class="me-2 v-avatar-light-bg primary--text">
                                    <span class="font-weight-semibold">{{
                                      avatarText(driverTransactions[0].drivername)
                                    }}</span>
                                  </v-avatar>
                                  <div>
                                    <p class="text--primary font-weight-medium mb-0">
                                      {{ driverTransactions[0].drivername }}
                                    </p>
                                    <span>{{ driverTransactions[0].driverphone }}</span>
                                  </div>
                                </div>
                              </v-timeline-item>

                              <v-timeline-item :color="otpVerifiedClass(selectedBooking).slice(0, -6)" small>
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">OTP verified by driver</h4>
                                  <!-- <small class="text-no-wrap"> Timestamp here </small> -->
                                </div>
                              </v-timeline-item>

                              <v-timeline-item
                                v-if="
                                  driverTransactions.length &&
                                  (driverTransactions[0].extra_amount_flag == true ||
                                    driverTransactions[0].extra_amount_flag == false)
                                "
                                :color="sendTrackingDetailsClass(selectedBooking).slice(0, -6)"
                                small
                              >
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">Ride Information</h4>
                                  <div class="d-flex">
                                    <v-btn
                                      @click="showtrackingurl(selectedBooking.m_share_url)"
                                      outlined
                                      color="primary"
                                      class="me-3"
                                    >
                                      View Tracking Url
                                    </v-btn>
                                  </div>
                                </div>

                                <div class="d-flex align-center mt-3">
                                  <div v-if="driverTransactions[0].extra_amount_flag == false">
                                    --No Extra Charges--
                                  </div>

                                  <div v-if="driverTransactions[0].extra_amount_flag == true">
                                    <p style="padding-bottom: 6px" class="text--primary font-weight-medium mb-0">
                                      Breakdown of Charges
                                    </p>

                                    <span
                                      >EXTRA HOURS({{ driverTransactions[0].extra_hrs }}): ₹{{
                                        formatNumber(driverTransactions[0].extra_hrs_rental)
                                      }}
                                      (@{{
                                        formatNumber(
                                          driverTransactions[0].extra_hrs * driverTransactions[0].extra_hrs_rental,
                                        )
                                      }})</span
                                    >
                                    <div>
                                      <p
                                        style="
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 5px;
                                          margin-top: 6px;
                                          padding-left: 5px;
                                        "
                                      >
                                        GARAGE TO PICKUP
                                        {{
                                          secondsToHms(driverTransactions[0].trip_summary.orders[0].order_duration)
                                        }}Hrs
                                      </p>
                                      <p
                                        style="
                                          padding-left: 5px;
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 5px;
                                        "
                                      >
                                        PICKUP TO DROPOFF :{{
                                          secondsToHms(
                                            parseFloat(driverTransactions[0].trip_summary.orders[1].order_duration) -
                                              parseFloat(driverTransactions[0].trip_summary.orders[0].order_duration),
                                          )
                                        }}Hrs
                                      </p>
                                      <p
                                        style="
                                          padding-left: 5px;
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 8px;
                                        "
                                      >
                                        DROPOFF TO GARAGE :{{
                                          secondsToHms(
                                            parseFloat(driverTransactions[0].trip_summary.trip_duration) -
                                              parseFloat(driverTransactions[0].trip_summary.orders[1].order_duration),
                                          )
                                        }}Hrs
                                      </p>
                                    </div>

                                    <span
                                      >EXTRA KMS({{ driverTransactions[0].extra_kms }}): ₹{{
                                        formatNumber(driverTransactions[0].extra_kms_rental)
                                      }}
                                      (@{{
                                        formatNumber(
                                          driverTransactions[0].extra_kms * driverTransactions[0].extra_kms_rental,
                                        )
                                      }})</span
                                    >
                                    <div>
                                      <p
                                        style="
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 5px;
                                          margin-top: 6px;
                                          padding-left: 5px;
                                        "
                                      >
                                        GARAGE TO PICKUP :{{
                                          driverTransactions[0].trip_summary.orders[0].order_distance / 1000
                                        }}Kms
                                      </p>
                                      <p
                                        style="
                                          padding-left: 5px;
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 5px;
                                        "
                                      >
                                        PICKUP TO DROPOFF :{{
                                          driverTransactions[0].trip_summary.orders[1].order_distance / 1000 -
                                          parseFloat(
                                            driverTransactions[0].trip_summary.orders[0].order_distance / 1000,
                                          )
                                        }}Kms
                                      </p>
                                      <p
                                        style="
                                          padding-left: 5px;
                                          color: rgba(231, 227, 252, 0.68);
                                          font-size: 0.6rem;
                                          margin-bottom: 8px;
                                        "
                                      >
                                        DROPOFF TO GARAGE :{{
                                          driverTransactions[0].trip_summary.trip_distance / 1000 -
                                          parseFloat(
                                            driverTransactions[0].trip_summary.orders[1].order_distance / 1000,
                                          )
                                        }}Kms
                                      </p>
                                    </div>
                                    <span v-if="driverTransactions[0].driver_bata_added == true"
                                      >DRIVER ALLOWANCE: ₹{{
                                        formatNumber(driverTransactions[0].driver_allowance_per_day)
                                      }}</span
                                    >

                                    <h4 class="font-weight-medium me-1" style="padding-top: 20px">
                                      TOTAL: ₹{{ formatNumber(driverTransactions[0].total_extra_amount) }}
                                    </h4>
                                    <h5 style="padding-top: 5px">
                                      Status : Paid <span v-if="driverTransactions[0].pay_by_cash == true">by Cash</span
                                      ><span v-if="driverTransactions[0].pay_by_cash == false">Online</span>
                                    </h5>
                                  </div>
                                </div>
                              </v-timeline-item>

                              <v-timeline-item color="error" small v-if="selectedBooking.iscancelled">
                                <div class="d-flex justify-space-between align-center flex-wrap">
                                  <h4 class="font-weight-medium me-1">Booking Cancelled</h4>
                                  <small class="text-no-wrap">{{
                                    formatDate(selectedBooking.cancelledon.toDate(), 'DD-MMM-YYYY hh:mm:ss A')
                                  }}</small>
                                </div>
                                <div class="d-flex align-center mt-3">
                                  <v-avatar size="40" color="primary" class="me-2 v-avatar-light-bg primary--text">
                                    <span class="font-weight-semibold">{{
                                      avatarText(selectedBooking.cancelled_by.name)
                                    }}</span>
                                  </v-avatar>
                                  <div>
                                    <p class="text--primary font-weight-medium mb-0">
                                      {{ selectedBooking.cancelled_by.name }} ({{ selectedBooking.cancelled_by.type }})
                                    </p>
                                    <span>{{ selectedBooking.cancellation_reason }}</span
                                    ><br />
                                    <span>Cancellation Charges - {{ selectedBooking.percent_deducted }}%</span><br />
                                    <span
                                      >Refund Amount
                                      {{ selectedBooking.type == 'Self Drive' ? '(includes deposit)' : '' }} -
                                      {{ $store.getters['appConfig/currencySymbol'] }}
                                      {{ selectedBooking.refund_amount }}</span
                                    >
                                  </div>
                                </div>
                              </v-timeline-item>
                            </v-timeline>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col>
                        <v-row>
                          <p class="text-h5">Personal Information</p>
                          <span class="ml-2 mt-1">
                            <v-chip class="v-chip-light-bg white--text" x-small disabled>Profile -></v-chip>
                          </span>
                        </v-row>
                        <v-row class="mb-4" no-gutters>
                          <v-col cols="6">
                            <span>Name</span>
                            <p>{{ selectedBooking.customer_name }}</p>
                          </v-col>
                          <v-col cols="6">
                            <span>Reporting Date & Time</span>
                            <p>{{ selectedBooking.start_date }} | {{ selectedBooking.start_time }}</p>
                          </v-col>
                          <v-col cols="12">
                            <span>Pickup Address</span>
                            <p>{{ selectedBooking.pickup_address }}</p>
                          </v-col>
                          <v-col cols="12">
                            <span>Dropoff Address</span>
                            <p>{{ selectedBooking.dropoffaddress }}</p>
                          </v-col>
                          <v-col cols="6">
                            <span>Contact Details</span><br />
                            <span class="white--text">{{ selectedBooking.email }}</span
                            ><br />
                            <span class="white--text">{{ selectedBooking.phone }}</span>
                          </v-col>
                          <v-col cols="6" v-if="selectedBooking.rp_pay_link !== undefined">
                            <span>Payment link</span><br />
                           <a :href="`${selectedBooking.rp_pay_link}`" target="_blank"> <span class="white--text">{{ selectedBooking.rp_pay_link }}</span></a>
                          </v-col>
                        </v-row>
                        <hr />
                        <p class="text-h5 mt-4">Booking Information</p>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <div class="d-flex">
                              <p class="mb-0" v-if="selectedBooking.type == 'Self Drive'">
                                {{ selectedBooking.carbrand }} {{ selectedBooking.carclass }}
                              </p>
                              <p class="mb-0" v-else>{{ selectedBooking.brand }} {{ selectedBooking.class }}</p>
                              <p v-if="selectedBooking.luxtype">&nbsp;- {{ selectedBooking.luxtype }}</p>
                            </div>
                            <v-avatar tile width="150" height="100">
                              <v-img contain :src="selectedBooking.imgcar"></v-img>
                            </v-avatar>
                          </v-col>
                          <v-col cols="6">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <span>Booking ID</span>
                                <p>{{ selectedBooking.booking_id }}</p>
                              </v-col>
                              <v-col cols="12">
                                <span>Location</span>
                                <p>{{ selectedBooking.location }}</p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="6">
                            <span>Type of Drive</span>
                            <p>
                              {{
                                selectedBooking.type == 'Self Drive' ? selectedBooking.type : selectedBooking.package
                              }}
                              <span v-if="selectedBooking.type == 'Self Drive'">
                                {{ selectedBooking.kms_limit }}Kms/day
                              </span>
                              <span v-if="selectedBooking.selectattype">
                                {{ selectedBooking.selectattype }}
                              </span>
                              <span v-if="selectedBooking.package_kms1"> {{ selectedBooking.package_kms1 }}Kms </span>
                            </p>
                          </v-col>
                          <v-col cols="6">
                            <span>No. of Days</span>
                            <p>{{ selectedBooking.days ? selectedBooking.days : 1 }}</p>
                          </v-col>
                          <v-col cols="12">
                            <v-row no-gutters>
                              <v-col>
                                <span>Start Date</span>
                                <p>{{ selectedBooking.start_date }} | {{ selectedBooking.start_time }}</p>
                              </v-col>
                              <v-col v-if="selectedBooking.end_date">
                                <span>End Date</span>
                                <p>{{ selectedBooking.end_date }} | {{ selectedBooking.end_time }}</p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" v-if="selectedBooking.comments">
                            <span>Comments</span>
                            <p>{{ selectedBooking.comments }}</p>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <span>Base Price</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{
                                  formatNumber(
                                    selectedBooking.basic_rental * (selectedBooking.days ? selectedBooking.days : 1),
                                  )
                                }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.toll">
                              <span>Toll</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.toll) }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.parking_fee">
                              <span>Parking Fee</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.parking_fee) }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.extra_kms">
                              <span>Extra Kms({{ selectedBooking.extra_kms }})</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{
                                  formatNumber(Math.ceil(selectedBooking.extra_kms_rental) * selectedBooking.extra_kms)
                                }}@{{ formatNumber(Math.ceil(selectedBooking.extra_kms_rental)) }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.extra_hrs">
                              <span>Extra Hrs({{ selectedBooking.extra_hrs }})</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{
                                  formatNumber(Math.ceil(selectedBooking.extra_hrs_rental) * selectedBooking.extra_hrs)
                                }}@{{ formatNumber(selectedBooking.extra_hrs_rental) }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.driver_allowance_charges">
                              <span>Driver Batta</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.driver_allowance_charges) }}
                              </p>
                            </div>
                            <div
                              class="d-flex justify-space-between"
                              v-if="selectedBooking.coupon_code && selectedBooking.coupon_code.length"
                            >
                              <span>Coupon Discount ({{ selectedBooking.coupon_code }})</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }} {{ selectedBooking.coupon_discount }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.creditused">
                              <span>Hype Discount Amount</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.creditused) }}
                              </p>
                            </div>
                            <div class="d-flex justify-space-between" v-if="selectedBooking.deposit">
                              <span>Deposit</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.deposit) }}
                              </p>
                            </div>
                            <div
                              class="d-flex justify-space-between"
                              v-if="
                                selectedBooking.previous_pending_payment !== 0 &&
                                selectedBooking.previous_pending_payment !== undefined
                              "
                            >
                              <span>Overdue</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.previous_pending_payment) }}
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between text-h6">
                              <span class="white--text">Total (Incl. GST)</span>
                              <p>
                                {{ $store.getters['appConfig/currencySymbol'] }}
                                {{ formatNumber(selectedBooking.amount) }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table :headers="transactionsColumnsHeaders" :items="driverTransactions" hide-default-footer>
                      <template v-slot:item.createdon="{ item }">
                        <span>{{ formatDate(item.createdon.toDate(), 'DD-MMM-YYYY') }}</span
                        ><br />
                        <span>{{ formatDate(item.createdon.toDate(), 'hh:mm:ss A') }}</span>
                        <br />
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div class="d-flex" v-if="!ppIsAvailable">
                      <v-btn color="primary" class="mb-4 ml-3" @click="pendingPayment(true)">
                        <v-icon size="17" class="me-1">
                          {{ icons.mdiPlus }}
                        </v-icon>
                        <span>Add</span>
                      </v-btn>
                    </div>
                    <v-data-table :headers="pendingColumnsHeaders" :items="pendingPaymentData" hide-default-footer>
                      <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" text x-small @click="pendingPayment(true)"> Edit </v-btn>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="driverDetailDialog" max-width="1000">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">Send Driver Details</div>
            <v-btn icon @click="driverDetailDialog = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
                  <v-text-field
                    v-model="driverDetails.name"
                    outlined
                    dense
                    :rules="[validators.required]"
                    validate-on-blur
                    label="Driver Name *"
                    placeholder="Driver Name"
                    hide-details="auto"
                    class="mb-6"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="driverDetails.phoneNo"
                    outlined
                    dense
                    validate-on-blur
                    :rules="[validators.required, validators.phoneNumberValidator]"
                    label="Driver Phone No. *"
                    placeholder="Driver Phone No."
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>

                  <v-text-field
                    v-model="driverDetails.carRegNumber"
                    outlined
                    dense
                    :rules="[validators.required]"
                    validate-on-blur
                    label="Car Registration No. *"
                    placeholder="Car Registration No."
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>

                  <v-text-field
                    v-model="driverDetails.customerPIN"
                    outlined
                    dense
                    :rules="[validators.required, validators.lengthValidator(driverDetails.customerPIN, 6)]"
                    validate-on-blur
                    label="Customer PIN *"
                    placeholder="Customer Pin"
                    hide-details="auto"
                    class="mb-6"
                  >
                  </v-text-field>

                  <v-btn color="primary" class="me-3" type="submit" :loading="loading"> Submit </v-btn>
                  <v-btn color="error" @click="driverDetailDialog = false"> Cancel </v-btn>
                </v-form>
              </v-col>
              <v-col>
                <v-textarea v-html="customerMessage"> </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="assignAgentDialog" v-model="assignAgentDialog" max-width="1000">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">Assign Vendor</div>
            <v-btn icon @click="assignAgentDialog = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row v-if="selectedBooking">
              <v-col>
                <v-select
                  v-model="selectedLocation"
                  outlined
                  validate-on-blur
                  :items="locations"
                  label="Select City *"
                  placeholder="Select City"
                  clearable
                  dense
                  hide-details="auto"
                  class="mb-6"
                  @change="handleSelectCity"
                >
                  <template v-slot:item="{ item }">
                    <v-row no-gutters>
                      <v-col cols="1" class="mr-n4">
                        <v-icon v-if="selectedBooking.agent_name.includes(item)" x-small color="success">{{
                          icons.mdiCircle
                        }}</v-icon>
                      </v-col>
                      <v-col>
                        <span class="mr-auto">{{ item }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
                <v-select
                  v-model="assignAgentData.name"
                  outlined
                  :rules="[validators.required]"
                  validate-on-blur
                  :items="agentOptions"
                  label="Select Vendor *"
                  placeholder="Select Vendor"
                  clearable
                  dense
                  hide-details="auto"
                  class="mb-6"
                >
                  <template v-slot:item="{ item }">
                    <v-row no-gutters>
                      <v-col cols="1" class="mr-n4">
                        <v-icon v-if="selectedBooking.agent_name.includes(item)" x-small color="success">{{
                          icons.mdiCircle
                        }}</v-icon>
                      </v-col>
                      <v-col>
                        <span class="mr-auto">{{ item }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <p><b>Booking ID: </b> {{ selectedBooking.booking_id }}</p>
                <p><b>Location: </b> {{ selectedBooking.location }}</p>
                <p><b>Customer: </b> {{ selectedBooking.customer_name }}</p>
                <p>{{ selectedBooking.pickup_address }}</p>
                <p><b>Start Date: </b> {{ selectedBooking.start_date }}</p>
                <p><b>End Date: </b> {{ selectedBooking.end_date }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="assignAgentData.sendConfirmation"
                  label="Send Booking confirmation SMS and Email to assigned vendor"
                ></v-checkbox>
                <v-btn color="primary" class="me-3" @click="confirmAssignAgent()"> Submit </v-btn>
                <v-btn color="error" @click="assignAgentDialog = false"> Cancel </v-btn>
              </v-col>
            </v-row>
            <div class="d-flex mt-n8" style="font-size: 10px">
              <v-icon class="ml-auto mr-1" x-small color="success" size="9">{{ icons.mdiCircle }}</v-icon>
              <span>Fleet available with the partner as per the database. Check availability.</span>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-if="cancelBookingDialog" v-model="cancelBookingDialog" max-width="500">
        <v-card>
          <v-card-title class="d-flex">
            <div class="text-h5 mr-auto">Cancel Booking</div>
            <v-btn icon @click="cancelBookingDialog = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Booking ID - {{ selectedBooking.booking_id }}</span
                ><br />
                <span>Customer Name - {{ selectedBooking.customer_name }}</span
                ><br />
                <span>Refund Amount - </span>
                <span v-if="cancellationRefundAmount == null">Calculating...</span>
                <span v-else
                  >{{ $store.getters['appConfig/currencySymbol'] }}
                  {{ formatNumber(cancellationRefundAmount) || 0 }}</span
                >

                <template v-if="cancellationRefundDeposit > 0">
                   <br />
                  <span
                    >Deposit - {{ $store.getters['appConfig/currencySymbol'] }}
                    {{ formatNumber(cancellationRefundDeposit) }}</span
                  >
                </template>
                <template>
                  <br />
                  <span
                    >Extra Hrs - {{ $store.getters['appConfig/currencySymbol'] }}
                    {{ formatNumber(extraHrs) || 0 }}</span
                  ><br />
                  <span
                    >Extra Kms - {{ $store.getters['appConfig/currencySymbol'] }}
                    {{ formatNumber(extraKms) || 0 }}</span
                  >
                  <br />
                  <span
                    >Total Refund - {{ $store.getters['appConfig/currencySymbol'] }}
                    {{ formatNumber(cancellationRefundAmount + cancellationRefundDeposit + extraKms + extraHrs) || 0 }}</span
                  >
                  <br />
                </template>
              </v-col>
              <v-col cols="12">
                <v-form ref="form1" v-model="valid1" @submit.prevent="onSubmitCancelBooking">
                  <v-select
                    v-model="cancellationReason"
                    outlined
                    :rules="[validators.required]"
                    validate-on-blur
                    :items="cancellationOptions"
                    label="Reason for cancellation *"
                    placeholder="Reason for cancellation"
                    dense
                    hide-details="auto"
                    class="mb-6"
                    :disabled="isSevenDays"
                  ></v-select>

                  <v-textarea
                    v-if="cancellationReason == 'Other'"
                    v-model="customCancellationReason"
                    outlined
                    dense
                    :rules="[validators.required]"
                    validate-on-blur
                    label="Reason for cancellation *"
                    placeholder="Reason for cancellation"
                    hide-details="auto"
                    class="mb-6"
                  ></v-textarea>
                  <p>
                    Note - This action can't be reversed and a cancellation charge of {{ cancellationPercentDeducted }}%
                    will be applied. Do you still want to go ahead and cancel the booking?
                  </p>
                  <v-radio-group v-model="refundRadio" class="mt-0" row :rules="rules" :disabled="isSevenDays">
                    <v-radio :label="`25%`" :value="25"></v-radio>
                    <v-radio :label="`100%`" :value="100"></v-radio>
                    <v-radio :label="`0%`" :value="0"></v-radio>
                  </v-radio-group>
                  <v-text-field
                    v-if="readioInputIsSelected == true"
                    v-model="refundInput"
                    label="Refund Amount %"
                    type="number"
                    outlined
                    dense
                    placeholder="Refund Amount"
                    hide-details="auto"
                    class="mb-6"
                  ></v-text-field>

                  <v-alert color="error" dark v-if="refundRadio !== null"
                    >Are you sure you want to go ahead with the cancellation? This action cannot be reversed.
                  </v-alert>
                  <v-checkbox
                    v-model="sendConfirmationCancel"
                    label="Send Booking Cancelation SMS and Email to assigned vendor"
                  ></v-checkbox>
                  <v-btn
                    color="error"
                    class="me-3"
                    type="submit"
                    :loading="loading"
                    :disabled="cancellationRefundAmount == null ? true : isSevenDays ? true : false"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="
                      cancelBookingDialog = false
                      refundInput = 0
                      refundRadio = null
                    "
                  >
                    Cancel
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="paymentFormModal" max-width="450">
        <AddPendingPayment
          v-if="paymentFormModal"
          :addPendingPayment="addPendingPayment"
          :error="error"
          :selectedBooking="selectedBooking"
          @close-dialog="paymentFormModal = false"
          @payment-data="e => emitPendingPayemt(e)"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import useExcel from '@/composables/useExcel'
import useMoment from '@/composables/useMoment'
import useAgent from '@/views/masters/agent/useAgent'
import axios from '@axios'
import useAppConfig from '@core/@app-config/useAppConfig'
import { avatarText, formatNumber } from '@core/utils/filter'
import { lengthValidator, phoneNumberValidator, required, requiredLength } from '@core/utils/validation'
import {
  mdiAndroid,
  mdiAppleIos,
  mdiCalendar,
  mdiCircle,
  mdiClose,
  mdiDotsVertical,
  mdiDownload,
  mdiExportVariant,
  mdiFilter,
  mdiWeb,
  mdiPlus,
} from '@mdi/js'
import { computed, onUnmounted, ref, watch } from '@vue/composition-api'
import { filter, map } from 'lodash'
import useCarBookings from './useCarBookings'
import AddPendingPayment from './AddPendingPayment.vue'
import usePendingPayment from './usePendingPayment'
import moment from 'moment'

export default {
  components: { AddPendingPayment },
  setup() {
    const { addPendingPayment } = usePendingPayment()
    const transactionsColumnsHeaders = [
      {
        text: 'Driver Name',
        value: 'drivername',
        sortable: false,
      },
      { text: 'Driver Phone No.', value: 'driverphone', sortable: false },
      { text: 'Vehicle Reg No.', value: 'driverreg', sortable: false },
      { text: 'Customer PIN', value: 'secretcode', sortable: false },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Created on', value: 'createdon', sortable: false },
    ]
    const pendingColumnsHeaders = [
      {
        text: 'Booking ID',
        value: 'booking_id',
      },
      { text: 'Pedding Amount', value: 'pending_payment_amount' },
      { text: 'Status', value: 'payment_status' },
      { text: 'Actions', value: 'actions' },
    ]
    const form = ref(null)
    const form1 = ref(null)
    const valid = ref(false)
    const valid1 = ref(false)
    const driverDetails = ref({
      name: '',
      phoneNo: '',
      carRegNumber: '',
      customerPIN: '',
    })
    const assignAgentData = ref({
      name: '',
      sendConfirmation: true,
      oldAgentName: '',
    })
    const detailDialog = ref(false)
    const confirmBookingDialog = ref(false)
    const assignAgentDialog = ref(false)
    const driverDetailDialog = ref(false)
    const cancelBookingDialog = ref(false)
    const cancellationReason = ref('')
    const customCancellationReason = ref('')
    const cancellationRefundAmount = ref(null)
    const cancellationRefundDeposit = ref(null)
    const cancellationPercentDeducted = ref(null)
    const cancellationOptions = [
      'Canceled By Hype',
      'Canceled By Customer',
      'Vendor cancelled',
      'Change of plans',
      'Delayed reporting',
      'Issues with the vehicle',
      'Severe weather conditions',
      'Non availability of fleet',
      'Background check failed',
      'Other',
    ]
    const detailTab = ref(false)
    const driverTransactions = ref([])
    const statusOptions = ['Done', 'Pending', 'Cancelled']
    const showFilters = ref(true)
    const dateModal = ref(false)
    const dateModal1 = ref(false)
    const selectedBooking = ref(null)
    const paymentFormModal = ref(false)
    const pendingPaymentData = ref([])
    const ppIsAvailable = ref(false)
    const selectedLocation = ref('')

    const refundRadio = ref(null)
    const refundInput = ref(0)
    const sendConfirmationCancel = ref(true)
    const isSevenDays = ref(false)
    const extraHrs = ref(0)
    const extraKms = ref(0)
    const rules = [value => (value !== 3 ? true : 'Do not select the third one!')]

    const readioInputIsSelected = ref(false)

    const customerMessage = computed(() => {
      const { name, phoneNo, carRegNumber, customerPIN } = driverDetails.value
      let message = ''
      if (selectedBooking.value) {
        message = `Hi ${selectedBooking.value.customer_name},<br><br>Your booking ${
          selectedBooking.value.booking_id
        } for ${
          selectedBooking.value.type == 'Self Drive'
            ? selectedBooking.value.carbrand + ' ' + selectedBooking.value.carclass
            : selectedBooking.value.brand + ' ' + selectedBooking.value.class
        } is confirmed with a vehicle number ${carRegNumber || '<>'}. Our Chauffeur ${
          name || '<>'
        } will arrive at your destination and he can be reachable at ${phoneNo || '<>'}. Please share OTP ${
          customerPIN || '<>'
        } with our Chauffeur. Enjoy your ride!<br><br>
      You can track and manage your booking details here!<br><br>
      https://bit.ly/3nQheRr<br><br>

      Cheers!<br>
      Team Hype
      `
      }
      return message
    })

    const { country } = useAppConfig()

    const {
      filteredCarBookingsList,
      selfDriveBookingsList,
      carBookingList,
      tableColumns,
      searchQuery,
      loading,
      options,
      dateRange,
      dateRange1,
      statusFilter,
      typeFilter,
      locationOptions,
      vendorOptions,
      typeOptions,
      locationFilter,
      vendorFilter,
      packageOptions,
      packageFilter,
      platformOptions,
      platformFilter,
      travelDateOptions,
      travelDateFilter,

      unsubscribeCarBookings,
      getCustomerData,
      sendDriverDetailsToCustomer,
      assignAgentToBooking,
      getDriverTransactions,
      confirmCarBooking,
      getVendors,
      cancelCarBooking,
      resportDownload,
      locations,
    } = useCarBookings(country.value)

    const { formatDate } = useMoment()

    onUnmounted(() => {
      unsubscribeCarBookings()
      unsubscribeAgents()
    })

    const fngeta2 = type => {
      //after.trip_summary.orders[0].order_duration
    }
    const showtrackingurl = value => {
      //  window.location.href = 'www.gohype.in'
      window.open(value, '_blank')
    }
    const secondsToHms = value => {
      const sec = parseInt(value, 10) // convert value to number if it's string
      let hours = Math.floor(sec / 3600) // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
      let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = '' + hours
      }
      if (minutes < 10) {
        minutes = '' + minutes
      }
      return hours + '.' + minutes
    }
    const getPlatformIconName = type => {
      if (type == 'Android') {
        return 'mdiAndroid'
      } else if (type == 'iOS') {
        return 'mdiAppleIos'
      }
      return 'mdiWeb'
    }
    const viewDetails = async item => {
      selectedBooking.value = item
      detailDialog.value = true
      driverTransactions.value = await getDriverTransactions(item.booking_id)
      ppIsAvailable.value = item.pending_payment == undefined ? false : true

      // if (item.pending_payment) {
      //   let final = [
      //     {
      //       booking_id: item.booking_id,
      //       pending_payment_amount: item.pending_amount,
      //       payment_status: item.pending_payment_status,
      //       pending_payment_note: item.pending_payment_note,
      //     },
      //   ]

      //   pendingPaymentData.value = final
      // } else {
      //   pendingPaymentData.value = []
      // }
    }

    watch([selectedBooking, refundRadio, refundInput, readioInputIsSelected], () => {
      cancellationRefundAmount.value = null
      cancellationRefundDeposit.value = null
      cancellationPercentDeducted.value = null
      extraHrs.value = 0
      extraKms.value = 0
      readioInputIsSelected.value = refundRadio.value == 0 ? true : false
      handleBookingCancel()
    })

    const handleBookingCancel = async () => {
      try {
        const response = await axios.post(
          'https://asia-east2-hypeprod-f3a90.cloudfunctions.net/cancelbooking/api/refund/amount',
          {
            booking_id: selectedBooking.value.booking_id,
            package: selectedBooking.value.type == 'Self Drive' ? 'SD' : 'CD',
            discount_per: readioInputIsSelected.value == true ? refundInput.value : refundRadio.value,
          },
        ).catch(err=>{
          console.log(err,"err********")
        })
        cancellationRefundAmount.value = response.data.refund_amount
        cancellationRefundDeposit.value = response.data.deposit
        cancellationPercentDeducted.value = response.data.percent_deducted
        extraHrs.value = response.data.extra_hrs
        extraKms.value = response.data.extra_kms
      } catch (e) {}
    }

    const cancelBooking = async item => {
      selectedBooking.value = item
      cancellationReason.value = ''
      cancellationRefundAmount.value = null
      cancellationRefundDeposit.value = null
      cancellationPercentDeducted.value = null
      cancelBookingDialog.value = true
      refundRadio.value = null
      refundInput.value = 0
      extraHrs.value = 0
      extraKms.value = 0

      let d = new Date(item.start_date)
      let d1 = new Date()
      console.log('Today is: ' + d.toLocaleString())
      d.setDate(d.getDate() + 7)
      console.log(d > d1, 'true or false')
      if (d > d1) {
        isSevenDays.value = false
      } else {
        isSevenDays.value = true
      }
    }

    const agentOptions = ref([])

    const { agents, unsubscribeAgents } = useAgent()

    const assignAgent = async item => {
      agentOptions.value = []
      selectedBooking.value = item
      assignAgentData.value.name = selectedBooking.value.agentname
      assignAgentData.value.oldAgentName = selectedBooking.value.agentname
      assignAgentData.value.sendConfirmation = true
      selectedLocation.value = selectedBooking.value.location
      // if (item.carId && item.carId.length) {
      //   agentOptions.value = await getVendors(item.type, item.carId)
      // } else {
      //   agentOptions.value = item.agent_name
      // }
      agentOptions.value = map(
        filter(agents.value, ({ location_id, active }) => selectedBooking.value.location == location_id && active),
        'fullname',
      )

      assignAgentDialog.value = true
    }

    const handleSelectCity = async () => {
      agentOptions.value = map(
        filter(agents.value, ({ location_id, active }) => selectedLocation.value == location_id && active),
        'fullname',
      )
    }

    const sendDriveDetails = async item => {
      selectedBooking.value = item
      driverDetails.value.name = ''
      driverDetails.value.phoneNo = ''
      driverDetails.value.carRegNumber = ''
      driverDetails.value.customerPIN = ''
      driverTransactions.value = await getDriverTransactions(item.booking_id)
      driverDetails.value.customerPIN = (
        driverTransactions.value && driverTransactions.value.length
          ? driverTransactions.value[0].secretcode
          : Math.floor(100000 + Math.random() * 900000)
      ).toString()
      driverDetailDialog.value = true
    }

    const validate = () => {
      form.value.validate()
    }

    const validateCancelBooking = async () => {
      await form1.value.validate()
    }

    const resetForm = async () => {
      await form.value.reset()
      selfDriveData.value = JSON.parse(JSON.stringify(blankSelfDriveData))
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        let packageType = selectedBooking.value.type == 'Self Drive' ? 'Self Drive' : selectedBooking.value.package
        if (packageType == 'Airport') {
          packageType = 'Airport Transfer'
        }
        let packageName = ''
        if (selectedBooking.value.package_id == '1') {
          packageName = '8Hrs ' + selectedBooking.value.package_kms1 + 'Kms'
        } else if (selectedBooking.value.package_id == '2') {
          let smsduration = selectedBooking.value.days
          if (parseFloat(smsduration) > 1) packageName = smsduration.toString() + ' days'
          else packageName = smsduration.toString() + ' day'
        }
        const customerImage = (await getCustomerData(selectedBooking.value.phone)).imageurl || ''
        let docData = {
          cusname: selectedBooking.value.customer_name,
          cusphone: selectedBooking.value.phone,
          carname:
            selectedBooking.value.type == 'Self Drive'
              ? selectedBooking.value.carbrand + ' ' + selectedBooking.value.carclass
              : selectedBooking.value.brand + ' ' + selectedBooking.value.class,
          bookingid: selectedBooking.value.booking_id,
          bookingdate: selectedBooking.value.start_date + ' ' + selectedBooking.value.start_time,
          package: packageType,
          sent_start_sms: false,
          drivername: driverDetails.value.name,
          driverphone: driverDetails.value.phoneNo,
          driverreg: driverDetails.value.carRegNumber,
          secretcode: parseFloat(driverDetails.value.customerPIN),
          imgurl: customerImage,
          startdate: selectedBooking.value.start_date,
          starttime: selectedBooking.value.start_time,
          pickup_address: selectedBooking.value.pickup_address,
          carimage: selectedBooking.value.imgcar,
          location: selectedBooking.value.location,
          packagename: packageName,
          status: 'Pending',
          mid: selectedBooking.value.id,
          active: true,
        }

        if (packageType == 'Airport Transfer' || packageType == 'Outstation' || packageType == 'Standard') {
          docData = {
            ...docData,
            pick_up_latlang: [Number(selectedBooking.value.pickuplat), Number(selectedBooking.value.pickuplong)],
            drop_off_address: selectedBooking.value.dropoffaddress || '',
            drop_of_latlang: [Number(selectedBooking.value.dropofflat), Number(selectedBooking.value.dropofflong)],
            package_kms: 'Airport Transfer'
              ? 100
              : Number(selectedBooking.value.package_kms1 || selectedBooking.value.package_kms2),
            package_hrs: packageType == 'Outstation' ? 24 : 8,

            driver_bata_added: !!selectedBooking.value.driver_allowance_charges,
            trip_stage: 'not_started',
          }
        }
        if (packageType == 'Airport Transfer') {
          docData = {
            ...docData,
            selectatport: selectedBooking.value.selectatport,
            selectattype: selectedBooking.value.selectattype,
          }
        }
        if (packageType == 'Outstation') {
          docData = {
            ...docData,

            enddate: selectedBooking.value.end_date,
            days: selectedBooking.value.days,
          }
        }
        await sendDriverDetailsToCustomer(docData)
        loading.value = false
        driverDetailDialog.value = false
      } else {
        validate()
      }
    }

    const onSubmitCancelBooking = async () => {
      await validateCancelBooking()
      if (valid1.value) {
        loading.value = true
        const reason =
          customCancellationReason.value == 'Other' ? customCancellationReason.value : cancellationReason.value
        await cancelCarBooking(
          selectedBooking.value.type,
          selectedBooking.value.id,
          reason,
          cancellationRefundAmount.value + cancellationRefundDeposit.value + extraHrs.value + extraKms.value,
          cancellationPercentDeducted.value,
          sendConfirmationCancel.value,
        )
        loading.value = false
        cancelBookingDialog.value = false
      }
    }

    const confirmAssignAgent = async () => {
      if (assignAgentData.value.name && assignAgentData.value.name.length) {
        loading.value = true
        const docData = {
          location: selectedLocation.value,
          agentname: assignAgentData.value.name,
          sendagentsms: assignAgentData.value.sendConfirmation,
          agentassigned: true,
          isAgentEdited: selectedBooking.value.agentassigned ? true : false,
          oldAgentName: selectedBooking.value.agentassigned ? assignAgentData.value.oldAgentName : '',
          agentsmssent: false,
          agentemailsent: false,
          hype_whatsapp_alert: false,
          exlocation: selectedBooking.value.location
        }
        
        await assignAgentToBooking(selectedBooking.value.type, selectedBooking.value.id, docData)
        loading.value = false
        assignAgentDialog.value = false
      }
    }

    const confirmBooking = async () => {
      loading.value = true
      await confirmCarBooking(selectedBooking.value.id, selectedBooking.value.type)
      confirmBookingDialog.value = false
      loading.value = false
    }

    const itemRowBackground = ({ booking_type, booking_id }) => {
      return booking_id && booking_type && booking_type == 'Offline' ? 'offline-booking' : null
    }

    const showConfirmBookingDialog = item => {
      selectedBooking.value = item
      confirmBookingDialog.value = true
    }

    const bookingCreatedClass = item => {
      return item.status == 'Done' ? 'success--text' : 'warning--text'
    }

    const assignAgentClass = item => {
      return item.agentname && item.agentname.length
        ? 'success--text'
        : item.status == 'Done'
        ? 'warning--text'
        : 'secondary--text'
    }

    const sendTrackingDetailsClass = item => {
      return item.m_share_url != undefined ? 'success--text' : item.m_share_url ? 'warning--text' : 'secondary--text'
    }

    const sendDriverDetailsClass = item => {
      return item.verified != undefined
        ? 'success--text'
        : item.agentname && item.agentname.length
        ? 'warning--text'
        : 'secondary--text'
    }

    const otpVerifiedClass = item => {
      return item.verified ? 'success--text' : item.verified != undefined ? 'warning--text' : 'secondary--text'
    }

    const { exportAsExcelFile } = useExcel()

    const exportToExcel = () => {
      const excelArray = filteredCarBookingsList.value.map(item => {
        return {
          'Booking Id': item.booking_id,
          Type: item.type == 'Self Drive' ? item.type : item.package,
          Car: item.type == 'Self Drive' ? item.carbrand + ' ' + item.carclass : item.brand + ' ' + item.class,

          'Start Date': item.start_date + ' ' + item.start_time,
          'End Date': item.end_date ? item.end_date + ' ' + item.end_time : '',
          Location: item.location,
          'Agent Name': item.agentname,
          'Customer Name': item.customer_name,
          Email: item.email,
          Phone: item.phone,
          Days: item.days,
          'Standard Package Kms': item.package_kms1,
          'Outstation Package Kms': item.package_kms2,

          'Coupon Code': item.coupon_code,
          'Coupon Discount': item.coupon_discount,
          'Pickup Address': item.pickup_address,
          'Parking Fees': item.parking_fee,
          'Airport Name': item.selectatport,
          'Airport Type': item.selectattype,
          Toll: item.toll,
          Price: item.price_per_day,
          'Driver Allowance Charges': item.driver_allowance_charges,
          'Kms Limit': item.kms_limit,
          Deposit: item.deposit,
          'Extra Kms': item.extra_kms,
          'Extra Kms Rental': item.extra_kms_rental,
          'Extra Hrs': item.extra_hrs,
          'Extra Hrs Rental': item.extra_hrs_rental,
          'Basic Rental': item.basic_rental,
          'Total Amount': item.amount,
          'Payment Status': item.status,

          platform: item.mplatform || 'Website',
          'Created on': item.createdon.toDate().toLocaleString('en-IN'),
        }
      })

      exportAsExcelFile(excelArray, 'Car_Bookings')
    }

    const pendingPayment = value => {
      paymentFormModal.value = value
    }

    const emitPendingPayemt = data => {
      pendingPaymentData.value = data
    }

    watch([carBookingList, selectedBooking], () => {
      let final = carBookingList.value.find(booking => booking.id == selectedBooking.value.id)
      selectedBooking.value = final
      if (final.pending_payment && final.booking_id !== undefined) {
        let finalData = [
          {
            booking_id: final.booking_id,
            pending_payment_amount: final.pending_amount,
            payment_status: final.pending_payment_status,
            pending_payment_note: final.pending_payment_note,
          },
        ]

        pendingPaymentData.value = finalData
        ppIsAvailable.value = true
      } else {
        ppIsAvailable.value = true
        pendingPaymentData.value = []
      }
    })

    return {
      country,
      avatarText,
      form,
      form1,
      valid,
      valid1,
      driverDetails,
      confirmBookingDialog,
      assignAgentData,
      customerMessage,
      detailTab,
      detailDialog,
      assignAgentDialog,
      driverDetailDialog,
      cancelBookingDialog,
      selectedBooking,
      dateModal,
      dateModal1,
      showFilters,
      filteredCarBookingsList,
      carBookingList,
      selfDriveBookingsList,
      tableColumns,
      searchQuery,
      loading,
      options,
      dateRange,
      dateRange1,
      statusOptions,
      statusFilter,
      typeFilter,
      viewDetails,
      cancelBooking,
      assignAgent,
      sendDriveDetails,
      formatDate,
      getPlatformIconName,
      secondsToHms,
      showtrackingurl,
      onSubmit,
      onSubmitCancelBooking,
      cancellationReason,
      customCancellationReason,
      cancellationRefundAmount,
      cancellationRefundDeposit,
      cancellationPercentDeducted,
      cancellationOptions,
      confirmAssignAgent,
      itemRowBackground,
      driverTransactions,
      transactionsColumnsHeaders,
      pendingColumnsHeaders,
      locationOptions,
      vendorOptions,
      typeOptions,
      locationFilter,
      vendorFilter,
      packageOptions,
      packageFilter,
      platformOptions,
      platformFilter,
      confirmBooking,
      travelDateOptions,
      travelDateFilter,
      showConfirmBookingDialog,
      bookingCreatedClass,
      assignAgentClass,
      sendDriverDetailsClass,
      sendTrackingDetailsClass,
      otpVerifiedClass,
      exportToExcel,
      agentOptions,
      formatNumber,
      // icons
      validators: { required, requiredLength, phoneNumberValidator, lengthValidator },
      icons: {
        mdiExportVariant,
        mdiFilter,
        mdiCalendar,
        mdiDotsVertical,
        mdiAndroid,
        mdiAppleIos,
        mdiWeb,
        mdiDownload,
        mdiClose,
        mdiCircle,
        mdiPlus,
      },
      paymentFormModal,
      pendingPayment,
      addPendingPayment,
      pendingPaymentData,
      ppIsAvailable,
      emitPendingPayemt,
      resportDownload,
      selectedLocation,
      handleSelectCity,
      locations,
      refundRadio,
      rules,
      refundInput,
      readioInputIsSelected,
      sendConfirmationCancel,
      isSevenDays,
      extraKms,
      extraHrs,
    }
  },
}
</script>

<style lang="scss" scoped>
.car-brand {
  .v-input {
    max-width: 200px;
  }

  .text-search {
    min-width: 300px;
  }

  ::v-deep .v-data-table__expanded__row > td {
    border: none !important;
  }
}

::v-deep .offline-booking {
  small,
  span {
    color: #e57373 !important;
  }
}

::v-deep .v-chip-light-bg {
  opacity: 1;
}

::v-deep .timeline {
  font-size: 0.8rem;

  .spacer {
    .line {
      margin-left: 5px;
      margin-right: 5px;
      width: 95%;
      height: 50%;
      border-bottom: 2px solid grey;
    }
  }
}

.action-item:hover {
  cursor: pointer;
}

.dialog-card {
  p {
    color: white;
  }
}

.action-chip {
  font-size: 10px;

  &-desc {
    font-size: 9px;
  }
}
</style>
